.DateRangePickerInput__withBorder {
    width: 350px !important;
    border-radius: 2px;
    border: 1px solid #dbdbdb
}

.fromDateHourContainer {
    text-align: center;
}

.timeSelect {
    text-align: center;
    font-size: 1.1rem;
    border: solid 1px #ebebeb;
    padding: 5px;
    cursor: pointer;
}

#time-popover #buttonContainer {
    position: absolute !important;
    right: 65px;
    margin: 0;
    bottom: 4px;
}

#time-popover .cancelBtn {
    position: absolute;
    right: 0;
    bottom: 4px;
    background: #989595;
    text-transform: capitalize;
    color: #fff;
    font-size: 12px;
    border-radius: 3px;
    padding: 7px 10px;
    cursor: pointer;
    margin-right: 4px;
    border: none;
}

#time-popover .cancelBtn:hover {
    background: #808080;
}

#time-popover .MuiPopover-root {
    background: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 960px) {
    #time-popover .fromDateTimeContainer {
        position: relative;
    }

    #time-popover .cancelBtn {
        position: static !important;
        z-index: 10;
        margin: 10px;
    }

    #time-popover #buttonContainer {
        z-index: 10;
        position: static !important;
        float: left !important;

    }

    #time-popover .activeNotifier {
        padding-bottom: 10px;
    }

    #time-popover .divCancelBtn {
        text-align: right;
        margin-top: -37px;
    }
}
