body {
    margin: 0;
    padding: 0;
    font-family: source-han-sans-japanese, sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* antd animation disable;*/
* {
    animation-duration: 0s !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bold {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 700;
    font-style: normal;
}

.light {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.medium {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 500;
    font-style: normal;
}

.normal {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.regular {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.react-datepicker__input-container input {
    border: none !important;
    width: 100% !important;
}

.collection-create-form_last-form-item {
    margin-bottom: 0;
}

.btnPrimary {
    color: #fff !important;
    background-color: rgb(138, 199, 122) !important;
    border-bottom: solid 3px #79b369 !important;
    text-decoration: none !important;
    border-radius: 5px !important;
    box-shadow: 0 5px 0 0 rgba(121, 179, 105, 0.004) !important;
}

.btnPrimary:active {
    transform: translateY(3px) !important;
    border-bottom: none !important;
}

.btnPrimary:disabled {
    /*transform: translateY(3px) !important;*/
    background-color: lightgray !important;
    border-bottom: solid 3px darkgray !important;
}

div[tabindex="-1"]:focus {
    outline: 0;
}

.clf-ant-relative {
    position: relative;
}

.rateOfTask circle {
    display: none;
}

.rateOfTask {
    height: 36px;
}

.w100 {
    width: 100%;
}

.fl-l {
    float: left;
}

.fl-r {
    float: right;
}

.ant-image-preview-img-wrapper img {
    border-radius: 10px !important;
}

/*@font-face {*/
/*    font-family: 'Material Icons';*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    !*src: url(https://example.com/MaterialIcons-Regular.eot); !* For IE6-8 *!*!*/
/*    src: local('Material Icons'),*/
/*    local('MaterialIcons-Regular')*/
/*    !*url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),*!*/
/*    !*url(https://example.com/MaterialIcons-Regular.woff) format('woff'),*!*/
/*    !*url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');*!*/
/*}*/

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.material-icons.md-14 {
    font-size: 14px;
}

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

.taskImg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.taskImgContainer {
    display: flex;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    background-color: grey;
    border-radius: 50%;
    margin-top: 2px;
}

.taskImgEvt {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.fc-event :hover .copyEventButton {
    display: flex;
}

.fc-event .locationIcon {
    display: flex;
    border: none;
    color: #ffffff;

}

.copyEventButton {
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    display: none;
    cursor: pointer;
}

.event-content-icons {
    position: relative;
    text-align: center;
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -2px;
}

.event-content-icons > * {
    position: absolute;
    transform: scale(0) translateX(0);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out,
    z-index 0s linear 0.25s;
    z-index: 0;
    opacity: 0;
    left: 0;
    width: 16px;
    height: 16px;
    padding: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-content: center;
}

.event-content-icons > *:first-child {
    opacity: 1;
    transform: scale(1) translateX(0);
}

.event-content-icons span {
    font-size: 16px;
}

.event-content-icons img {
    width: 14px;
    height: 14px;
}

.event-content-icons > .cf-slider__item__1 {
     transform: scale(1) translateX(0);
     opacity: 1;
     z-index: 10;
 }

.event-content-icons > .cf-slider__item__2 {
     transform: scale(0) translateX(0);
     opacity: 0.2;
     z-index: 5;
 }

.event-content-icons > .cf-slider__item__3 {
     transform: scale(0) translateX(0);
     opacity: 0.1;
     z-index: 1;
 }
