@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');*/

.App {
    overflow-y: visible;
    text-align: center;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: source-han-sans-japanese, sans-serif !important;
    font-size: 12px;
    overflow-y: hidden;
}

/*html, body::-webkit-scrollbar {*/
/*    width: 8px;*/
/*}*/

/*html, body::-webkit-scrollbar-thumb {*/
/*    border-radius: 10px;*/
/*    background-color: #ccc;*/
/*}*/


button {
    outline: none !important;
}

#external-events {
    z-index: 2;
    top: 20px;
    left: 20px;
    width: auto;
    padding: 0 10px;
    border: 4px solid #ccc;
    background: #eee;
}

#external-events .fc-event {
    margin: 1em 0;
    cursor: move;
}

#calendar-container {
    position: relative;
    z-index: 1;
}

/*!
 * FullCalendar v4.0.0-alpha.3
 * Docs & License: https://fullcalendar.io/
 * (c) 2018 Adam Shaw
 */
.fc {
    direction: ltr;
    text-align: left;
}

.fc-rtl {
    text-align: right;
}

body .fc {
    /* extra precedence to overcome jqui */
    font-size: 1em;
}

/* Colors

.pac-container {
    z-index: 9999 !important;
}

.div-charts {
    max-height: 70px;
}


/* header mobile */
/*.headerLeft{
    width: 12.5%;
}

@media only screen and (max-width: 600px) {
    .headerLeft{
        width: 50px;
    }
}*/

.ant-select-dropdown, .ant-picker-dropdown{
    z-index: 1350 !important;
}


.div-calendar .fc-time-grid .fc-slats tr td:last-child {
    position: relative;
    cursor: pointer;
}

.div-calendar .fc-time-grid .fc-slats tr td:last-child:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: dashed 1px #eaeaea;
    height: 1px;
}

.div-calendar .fc-widget-content {
    /*overflow-y: auto;*/
    height: 100%;
}


#calendarRoot .fc-time-grid-container .fc-slats tr:hover {
    background: #fcf8e3;
}

table.fc-agenda-slots td.fc-widget-content {
    border-right: 1px #DDD solid;
}

table.fc-agenda-slots td.fc-widget-content:hover {
    background-color: #F1F1F1;
}

.clearfix {
    /*overflow: auto;*/
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

#meeting .fc-unthemed td {
    border-color: #fff;
    z-index: 2;
    opacity: 1;
}

#time .DateRangePicker {
    z-index: 10;
}

#time .DateRangePickerInput__withBorder {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#time .DateInput_input__small {
    font-size: 1rem;
    color: #000;
}

#time .DateInput__small {
    width: auto !important;
}

#time .DateInput_input__focused {
    outline: none;
    color: #000;
}

.editVote .daterangepicker {
    position: static;
}

.editVote input {
    width: 100%;
    padding: 5px;
    outline: none;
    text-align: center;
}

.meetingSuccses {
    -webkit-animation: succes 1s infinite;
            animation: succes 1s infinite;
    font-size: 13px;
    display: inline-block;


}

@-webkit-keyframes succes {
    from {
        color: #000;
    }
    to {
        color: #ff0000;
    }

}

@keyframes succes {
    from {
        color: #000;
    }
    to {
        color: #ff0000;
    }

}

.timeDrag {
    display: none;
}

.fc-mirror .timeDrag {
    display: block;
    font-size: 7px;
}

.colorWhite {
    color: #fff;
}

.colorWhite svg {
    max-width: 15px;
    max-height: 15px;
}

.colorBlack {
    color: #000
}

.colorBlack svg {
    max-width: 15px;
    max-height: 15px;
}

/*svg {*/
/*    max-width: 15px;*/
/*}*/

.div-calendar .fc-body > tr {
    overflow-y: hidden;
}

.div-calendar .fc-body > tr > td {
    border: none;
}

.timeCalendar {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
}

.timeCalendar .about {
    margin: 0 10px;
}

.timeCalendar .bp3-input-group {
    width: 205px;
}

.scrollLeftEvent {
    overflow: hidden;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 84px;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
    padding: 0px 10px 10px 0;
    /*background: #f7f7f7;*/
    box-sizing: border-box;
}

.leftEventMobile {
    position: static;
    min-width: auto;
}

.scrollLeftEvent:hover {
    overflow: auto;

}

.scrollLeftEvent::-webkit-scrollbar {
    display: none
}

.contentEvent {
    overflow-y: hidden;
    margin-left: 16.666667%;
    overflow-x: auto;
}

.contentEventMobile {
    margin-left: 0px;
    overflow: auto;
}

.hiddenDisplay {
    display: none;
}

.titleChat {
    line-height: 25px;
    background: #1a73e8;
    color: #fff;
    width: 350px;
    text-align: left;
    padding: 10px;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
    position: relative;
    display: flex;
}

.titleChatFocus {
    line-height: 25px;
    background: #1a73e8;
    color: #fff;
    width: 350px;
    text-align: left;
    padding: 10px;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
    position: relative;
    display: flex;
    animation: colorBackground 1.5s ease-out infinite;
    -moz-animation: colorBackground 1.5s ease-out infinite;
    -webkit-animation: colorBackground 1.5s ease-out infinite;
    -o-animation: colorBackground 1.5s ease-out infinite;

}

.cursorPointer {
    cursor: pointer;
}

@-webkit-keyframes colorBackground {
    0% {
        background-color: #fff;
        color: #000;
    }
    50% {
        background-color: #1a73e8;;
        color: #fff;
    }
}

@keyframes colorBackground {
    0% {
        background-color: #fff;
        color: #000;
    }
    50% {
        background-color: #1a73e8;;
        color: #fff;
    }
}

@media screen and (max-width: 1400px) {
    .scrollLeftEvent {
        min-width: 260px;
    }

    .contentEvent {
        margin-left: 260px;
    }
}

@media screen and (max-width: 1200px) {


    .timeCalendar .bp3-input-group {
        width: auto;
    }
}

@media screen and (max-width: 960px) {
    .DateRangePicker_picker__directionLeft {
        left: 0 !important;
    }

    .titleChat {
        width: 250px;
    }

    .titleChatFocus {
        width: 250px;
    }

    .timeCalendar {
        /*flex-direction: column;*/
    }

    .timeCalendar .about {
        margin: 0 3px;
    }
}

.test {
    margin: 10px 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    max-height: 495px;
}

@media screen and (max-height: 760px) {
    .test {
        max-height: 270px;
    }
}

/*"&::-webkit-scrollbar": {*/
/*                          width: 8,*/
/*                      },*/
/*"&::-webkit-scrollbar-thumb": {*/
/*                                borderRadius: 10,*/
/*                                backgroundColor: '#ccc',*/
/*                            },*/
/*"@media (min-height: 760px)": {*/
/*                                height: '395px',*/
/*                            }*/
/*}*/
@media screen and (max-width: 600px) {
    .leftEventMobile, .scrollLeftEvent {
        position: static;
        min-width: auto;
    }

    .contentEvent {
        margin-left: 0px;
    }

    .timeCalendar {
        flex-direction: column;
    }

}

#calendarRoot .fc-content-skeleton {
    padding-bottom: 0;
}

.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
    height: auto !important;
}

.smooth-dnd-container.horizontal {
    display: flex !important;
    flex-wrap: wrap;
}

body {
    font-family: source-han-sans-japanese, sans-serif !important;
    color: #000000;
    line-height: 1.3;
}

.fontSourceHanSans {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.MuiTypography-root, .MuiTab-wrapper {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.tracks div {
    font-family: source-han-sans-japanese, sans-serif !important;
}

#tutorial-settings .MuiInputBase-root, #tutorial-settings .MuiInputBase-input {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.ItemCheckedCustom h1 {
    font-family: source-han-sans-japanese, sans-serif !important;
}

/*.fontBebasNeue {*/
/*    font-family: source-han-sans-japanese, sans-serif !important;*/
/*}*/

.isSun {
    color: #ff867f;
}

.isSat {
    color: #1B97F0;
}

.headerCalendar {
    font-weight: bold;
    position: relative;
    border: solid 1px transparent;
    font-size: 11px;
}

.headerCalendar .headerHtml {
    height: 25px;
    line-height: 25px;
    margin: 2px auto;
    text-align: center;
}


.isSelection {
    border: solid 1px red;
}

.material-icons-fix {
    vertical-align: middle;
}

.div-calendar .fc-time-grid .fc-slats tr td:last-child:hover {
    background: #fcf8e3;
}

.div-calendar .fc-time-grid > .fc-bg {
    z-index: 2 !important;
}

.div-calendar .fc-time-grid > .fc-bg .fc-day {
    cursor: pointer;
}

.headerCalendar {
    cursor: pointer;
}

.calendarDivCol3 .headerCalendar {
    font-size: 6px;
}

.calendarDivCol3 .headerCalendar .headerDay, .calendarDivCol2 .headerCalendar .headerDay {
    padding: 4px 0;
}

.calendarDivCol3 .headerCalendar .isToday {
    width: 10px;
    height: 10px;
    line-height: 11px;
    margin: 9px auto;
}

.calendarDivCol2 .headerCalendar {
    font-size: 9px;
}

.calendarDivCol2 .headerCalendar .isToday {
    width: 15px;
    height: 15px;
    line-height: 15px;
    margin: 7px auto;
}

.popNotification {
    position: absolute;
    top: 10px;
    right: 50px
}

.popNotification button:hover {
    background-color: unset;
}

.ant-btn-clicked:after {
    -webkit-animation: none !important;
    animation: none !important;
}

[ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    animation: none !important;
}

.fc-event {
    border-width: 3px !important;
}

.pastEvent {
    opacity: 1;
}

/*Custom Calendar*/
.headerCalendar {
    color: #000;
}

.fc-theme-standard td, .fc-theme-standard th {
    /*background: #fff;*/
    border: solid 1px #e7e5e5 !important;
}

.fc-theme-standard .fc-scrollgrid {
    border: none !important;
}

.fc .fc-scroller-harness {
    background: #fff;
}

.fc-col-header-cell-cushion {
    display: block !important;
    padding: 0 !important;
}

.headerDay {
    border-bottom: solid 1px #fff;
    padding: 4px 0;
    text-overflow: clip;
    overflow: hidden;
}

.fc .fc-timegrid-slot-label {
    border: none !important;
    font-weight: bold;
    font-size: 11px;
}

.fc .fc-timegrid-axis-frame {
    font-size: 11px;
    font-weight: bold;
}

.fc-direction-ltr .fc-timegrid-col-events {
    margin: 0 1px !important;
}

.fc-day-sat .headerCalendar {
    color: #1790FF;
}

.fc-day-sun .headerCalendar {
    color: #ff0000;
}

.headerCalendar .isToday {
    width: 21px;
    height: 21px;
    line-height: 21px;
    margin: 4px auto;
    text-align: center;
    background-color: #1790FF;
    color: #fff;
    border-radius: 50%;
}

.fc-timegrid-slots table tbody tr {
    font-size: 8px;
}

.fc .fc-timegrid-slot-label {
    font-size: 8px;
}

.fc .fc-timegrid-slot {
    height: unset !important;
}

.fc-timegrid-slot-lane:hover {
    background: #f3eed3;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    padding-top: 5px;
    /*background-color: #f1f1f1;*/
}

.fc .fc-timegrid-slot-label-cushion {
    background-color: #fff;
    padding: 0 2px 0 8px !important;
}

/*.fc .fc-daygrid-event-harness {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*}*/

.fc .fc-timegrid-slots > table > tbody > tr:nth-child(1) > td:nth-child(1) {
    /*display: none;*/
}

.fc-timegrid-slots table tbody tr {
    border-bottom: solid 1px #434343;
}

.buttonIcon2 {
    margin-left: 5px;
    margin-bottom: 5px;
    border: none !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: #fff !important;
    box-shadow: none;

}

.buttonIcon2 img {
    width: 100%;
    height: 100%;
    max-width: 30px;
}

.eventRenderCustom1 {
    position: relative;
}

.eventRenderCustom1 .checkBoxRenderEvent {
    position: absolute;
    top: 0;
    right: 0;
}

.eventRenderCustom2 {
    position: relative;
}

.eventRenderCustom2Height {
    min-height: 10px;
    position: relative;
}

.eventRenderCustom2 .checkBoxRenderEvent {
    position: absolute;
    bottom: 1px;
    /*left: 50%;*/
    /*margin-left: -7px;*/
}

.eventRenderCheckBox {
    /*position: absolute;*/
    bottom: 0px;
    left: 50%;
    width: 16px;
    height: 16px;
    object-fit: contain;
    /*margin-left: -8px;*/
}

.eventRenderMile {
    position: absolute;
    width: 16px;
    height: 16px;
    object-fit: contain;
    top: 0px;
    right: 0px;
}

.eventRenderMile span {
    font-size: 15px;
}

.eventRenderCheckBox span {
    font-size: 15px;
}

.ck-rounded-corners .ck.ck-balloon-panel, .ck.ck-balloon-panel.ck-rounded-corners {
    z-index: 9999 !important;
}

.hiddenArrowInput input::-webkit-outer-spin-button,
.hiddenArrowInput input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.hiddenArrowInput input[type=number] {
    -moz-appearance: textfield !important;
}

/*input[type=number]::-webkit-inner-spin-button,*/
/*input[type=number]::-webkit-outer-spin-button {*/
/*    -webkit-appearance: none;*/
/*    margin: 0;*/
/*}*/

@keyframes: global(Toastify__slideInUp) {
    from {
        -webkit-transform: translate3d(0, 110%, 0);
                transform: translate3d(0, 110%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

.Toastify__toast-container {
    z-index: 99999 !important;
}

/*.MuiPopover-root {*/
/*    z-index: 999 !important;*/
/*}*/

.root {
    margin-left: 20px;
    background-color: #434343;
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 20px 10px 20px;
}

.root:before {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: #434343;
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transform: skew(-23.4deg, 0deg);
            transform: skew(-23.4deg, 0deg);
}


.iconEvent {
    font-size: 18px !important;
}


/* z-index */
/*.ant-message{*/
/*    z-index: 2000 !important;*/
/*  }*/

.ant-popconfirm{
      z-index: 1300 !important;
}


.flag-icon-jp {
    width: 30px;
    height: 30px;
    margin: 2px;
    cursor: pointer;
}

.flag-icon .flag-icon-jp span:hover {
    cursor: pointer;
    opacity: 0.8;
}

.flag-icon-us {
    width: 30px;
    height: 30px;
    margin: 2px;
    cursor: pointer;

}

.flag-icon .flag-icon-us span:hover {
    cursor: pointer;
    opacity: 0.8;
}

.DateRangePickerInput__withBorder {
    width: 350px !important;
    border-radius: 2px;
    border: 1px solid #dbdbdb
}

.fromDateHourContainer {
    text-align: center;
}

.timeSelect {
    text-align: center;
    font-size: 1.1rem;
    border: solid 1px #ebebeb;
    padding: 5px;
    cursor: pointer;
}

#time-popover #buttonContainer {
    position: absolute !important;
    right: 65px;
    margin: 0;
    bottom: 4px;
}

#time-popover .cancelBtn {
    position: absolute;
    right: 0;
    bottom: 4px;
    background: #989595;
    text-transform: capitalize;
    color: #fff;
    font-size: 12px;
    border-radius: 3px;
    padding: 7px 10px;
    cursor: pointer;
    margin-right: 4px;
    border: none;
}

#time-popover .cancelBtn:hover {
    background: #808080;
}

#time-popover .MuiPopover-root {
    background: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 960px) {
    #time-popover .fromDateTimeContainer {
        position: relative;
    }

    #time-popover .cancelBtn {
        position: static !important;
        z-index: 10;
        margin: 10px;
    }

    #time-popover #buttonContainer {
        z-index: 10;
        position: static !important;
        float: left !important;

    }

    #time-popover .activeNotifier {
        padding-bottom: 10px;
    }

    #time-popover .divCancelBtn {
        text-align: right;
        margin-top: -37px;
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: source-han-sans-japanese, sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* antd animation disable;*/
* {
    -webkit-animation-duration: 0s !important;
            animation-duration: 0s !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bold {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 700;
    font-style: normal;
}

.light {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.medium {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 500;
    font-style: normal;
}

.normal {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.regular {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.react-datepicker__input-container input {
    border: none !important;
    width: 100% !important;
}

.collection-create-form_last-form-item {
    margin-bottom: 0;
}

.btnPrimary {
    color: #fff !important;
    background-color: rgb(138, 199, 122) !important;
    border-bottom: solid 3px #79b369 !important;
    text-decoration: none !important;
    border-radius: 5px !important;
    box-shadow: 0 5px 0 0 rgba(121, 179, 105, 0.004) !important;
}

.btnPrimary:active {
    -webkit-transform: translateY(3px) !important;
            transform: translateY(3px) !important;
    border-bottom: none !important;
}

.btnPrimary:disabled {
    /*transform: translateY(3px) !important;*/
    background-color: lightgray !important;
    border-bottom: solid 3px darkgray !important;
}

div[tabindex="-1"]:focus {
    outline: 0;
}

.clf-ant-relative {
    position: relative;
}

.rateOfTask circle {
    display: none;
}

.rateOfTask {
    height: 36px;
}

.w100 {
    width: 100%;
}

.fl-l {
    float: left;
}

.fl-r {
    float: right;
}

.ant-image-preview-img-wrapper img {
    border-radius: 10px !important;
}

/*@font-face {*/
/*    font-family: 'Material Icons';*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    !*src: url(https://example.com/MaterialIcons-Regular.eot); !* For IE6-8 *!*!*/
/*    src: local('Material Icons'),*/
/*    local('MaterialIcons-Regular')*/
/*    !*url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),*!*/
/*    !*url(https://example.com/MaterialIcons-Regular.woff) format('woff'),*!*/
/*    !*url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');*!*/
/*}*/

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    -webkit-font-feature-settings: 'liga';
            font-feature-settings: 'liga';
}

.material-icons.md-14 {
    font-size: 14px;
}

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

.taskImg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.taskImgContainer {
    display: flex;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    background-color: grey;
    border-radius: 50%;
    margin-top: 2px;
}

.taskImgEvt {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.fc-event :hover .copyEventButton {
    display: flex;
}

.fc-event .locationIcon {
    display: flex;
    border: none;
    color: #ffffff;

}

.copyEventButton {
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    display: none;
    cursor: pointer;
}

.event-content-icons {
    position: relative;
    text-align: center;
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -2px;
}

.event-content-icons > * {
    position: absolute;
    -webkit-transform: scale(0) translateX(0);
            transform: scale(0) translateX(0);
    transition: opacity 0.5s ease-in-out,
    z-index 0s linear 0.25s, -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out,
    z-index 0s linear 0.25s;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out,
    z-index 0s linear 0.25s, -webkit-transform 0.5s ease-in-out;
    z-index: 0;
    opacity: 0;
    left: 0;
    width: 16px;
    height: 16px;
    padding: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-content: center;
}

.event-content-icons > *:first-child {
    opacity: 1;
    -webkit-transform: scale(1) translateX(0);
            transform: scale(1) translateX(0);
}

.event-content-icons span {
    font-size: 16px;
}

.event-content-icons img {
    width: 14px;
    height: 14px;
}

.event-content-icons > .cf-slider__item__1 {
     -webkit-transform: scale(1) translateX(0);
             transform: scale(1) translateX(0);
     opacity: 1;
     z-index: 10;
 }

.event-content-icons > .cf-slider__item__2 {
     -webkit-transform: scale(0) translateX(0);
             transform: scale(0) translateX(0);
     opacity: 0.2;
     z-index: 5;
 }

.event-content-icons > .cf-slider__item__3 {
     -webkit-transform: scale(0) translateX(0);
             transform: scale(0) translateX(0);
     opacity: 0.1;
     z-index: 1;
 }

.wf-materialicons-n4-inactive .material-icons, .wf-materialicons-n4-loading .material-icons{
    visibility: hidden;
}

.fw700 {
    font-weight: 700;
}

.px14 {
    font-size: 0.875rem;
    line-height: 1rem;
}

.brd20 {
    border-radius: 1.25rem;
}

.clf-flex {
    display: flex;
}

.clf-flex-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.clf-grid-center {
    display: grid;
    justify-content: center;
    align-items: center;
}

.clf-block-center {
    display: block;
    justify-content: center;
    align-items: center;
}

.clf-space-between {
    width: 100%;
    justify-content: space-between;
    /*max-width: 210px;*/
}

.clf-bebas-neue {

}

.clf-left-10 {
    margin-left: 10px;
}

.clf-right-10 {
    margin-right: 10px;
}

.clf-cursor-pointer {
    cursor: pointer;
}

.clf-no-border {
    border: none !important;
}

.clf-input {
    width: 50px !important;
}

.clf-input input {
    font-size: 16px;
    font-weight: bold;
}

.clf-svg-color {
    fill: currentColor;
}

.circle-picker {
    justify-content: center;
}

.clf-padding3 {
    padding: 3px !important;
}

@media (max-width: 1600px) {
    .clf-space-between {
        max-width: 210px;
        justify-content: flex-start;
    }
}

/*.clf-montserrat {*/
/*    font-family: Montserrat sans-serif !important;*/
/*}*/

.hover-show-tooltip .ant-tooltip-inner {
    background-color: #F1F1F1;
    border-radius: 10px;
    padding: 5px;
    /*box-shadow: 1px 1px 3px 3px #9d9b9b;*/
}

.hover-show-tooltip * {
    -webkit-animation-duration: 2s !important;
            animation-duration: 2s !important;
}

.hover-show-tooltip .ant-tooltip-arrow-content {
    background-color: #F1F1F1;
}

.hover-show-tooltip .ant-tooltip-content {
    min-width: 180px;
}

.hover-show-tooltip-2 .ant-tooltip-content {
    min-width: 100px !important;
}

.max-width-content .ant-tooltip-content {
    width: -webkit-max-content;
    width: max-content;
}

.dropdown-nums .ant-dropdown-menu-items {
    padding: 0;
}

.clf-event-render {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    min-height: 10px;
    justify-content: space-between;
    padding-bottom: 2px;
    position: relative;
}

.pac-container {
    z-index: 9999;
}


#tooltip {
    display: inline-block;
    background: #ffffff;
    /*color: #643045;*/
    font-weight: bold;
    padding: 0 !important;
    font-size: 13px;
    border-radius: 10px;
    overflow: hidden;
}

#arrow,
#arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
}

#arrow {
    visibility: hidden;
}

#arrow::before {
    visibility: visible;
    content: '';
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > #arrow {
    bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
    top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
    right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
    left: -4px;
}

.fc .fc-timegrid-divider {
    height: 0;
    padding: 0;
    border: none !important;
    background-color: #ddd;
}

.ant-picker-dropdown.ant-slide-up-enter {
    opacity: 1 !important;
}

