.wf-materialicons-n4-inactive .material-icons, .wf-materialicons-n4-loading .material-icons{
    visibility: hidden;
}

.fw700 {
    font-weight: 700;
}

.px14 {
    font-size: 0.875rem;
    line-height: 1rem;
}

.brd20 {
    border-radius: 1.25rem;
}

.clf-flex {
    display: flex;
}

.clf-flex-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.clf-grid-center {
    display: grid;
    justify-content: center;
    align-items: center;
}

.clf-block-center {
    display: block;
    justify-content: center;
    align-items: center;
}

.clf-space-between {
    width: 100%;
    justify-content: space-between;
    /*max-width: 210px;*/
}

.clf-bebas-neue {

}

.clf-left-10 {
    margin-left: 10px;
}

.clf-right-10 {
    margin-right: 10px;
}

.clf-cursor-pointer {
    cursor: pointer;
}

.clf-no-border {
    border: none !important;
}

.clf-input {
    width: 50px !important;
}

.clf-input input {
    font-size: 16px;
    font-weight: bold;
}

.clf-svg-color {
    fill: currentColor;
}

.circle-picker {
    justify-content: center;
}

.clf-padding3 {
    padding: 3px !important;
}

@media (max-width: 1600px) {
    .clf-space-between {
        max-width: 210px;
        justify-content: flex-start;
    }
}

/*.clf-montserrat {*/
/*    font-family: Montserrat sans-serif !important;*/
/*}*/

.hover-show-tooltip .ant-tooltip-inner {
    background-color: #F1F1F1;
    border-radius: 10px;
    padding: 5px;
    /*box-shadow: 1px 1px 3px 3px #9d9b9b;*/
}

.hover-show-tooltip * {
    animation-duration: 2s !important;
}

.hover-show-tooltip .ant-tooltip-arrow-content {
    background-color: #F1F1F1;
}

.hover-show-tooltip .ant-tooltip-content {
    min-width: 180px;
}

.hover-show-tooltip-2 .ant-tooltip-content {
    min-width: 100px !important;
}

.max-width-content .ant-tooltip-content {
    width: max-content;
}

.dropdown-nums .ant-dropdown-menu-items {
    padding: 0;
}

.clf-event-render {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    min-height: 10px;
    justify-content: space-between;
    padding-bottom: 2px;
    position: relative;
}

.pac-container {
    z-index: 9999;
}


#tooltip {
    display: inline-block;
    background: #ffffff;
    /*color: #643045;*/
    font-weight: bold;
    padding: 0 !important;
    font-size: 13px;
    border-radius: 10px;
    overflow: hidden;
}

#arrow,
#arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
}

#arrow {
    visibility: hidden;
}

#arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > #arrow {
    bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
    top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
    right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
    left: -4px;
}

.fc .fc-timegrid-divider {
    height: 0;
    padding: 0;
    border: none !important;
    background-color: #ddd;
}

.ant-picker-dropdown.ant-slide-up-enter {
    opacity: 1 !important;
}
