
.flag-icon-jp {
    width: 30px;
    height: 30px;
    margin: 2px;
    cursor: pointer;
}

.flag-icon .flag-icon-jp span:hover {
    cursor: pointer;
    opacity: 0.8;
}

.flag-icon-us {
    width: 30px;
    height: 30px;
    margin: 2px;
    cursor: pointer;

}

.flag-icon .flag-icon-us span:hover {
    cursor: pointer;
    opacity: 0.8;
}
