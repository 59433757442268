/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.App {
    overflow-y: visible;
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: source-han-sans-japanese, sans-serif !important;
    font-size: 12px;
    overflow-y: hidden;
}

/*html, body::-webkit-scrollbar {*/
/*    width: 8px;*/
/*}*/

/*html, body::-webkit-scrollbar-thumb {*/
/*    border-radius: 10px;*/
/*    background-color: #ccc;*/
/*}*/


button {
    outline: none !important;
}

#external-events {
    z-index: 2;
    top: 20px;
    left: 20px;
    width: auto;
    padding: 0 10px;
    border: 4px solid #ccc;
    background: #eee;
}

#external-events .fc-event {
    margin: 1em 0;
    cursor: move;
}

#calendar-container {
    position: relative;
    z-index: 1;
}

/*!
 * FullCalendar v4.0.0-alpha.3
 * Docs & License: https://fullcalendar.io/
 * (c) 2018 Adam Shaw
 */
.fc {
    direction: ltr;
    text-align: left;
}

.fc-rtl {
    text-align: right;
}

body .fc {
    /* extra precedence to overcome jqui */
    font-size: 1em;
}

/* Colors

.pac-container {
    z-index: 9999 !important;
}

.div-charts {
    max-height: 70px;
}


/* header mobile */
/*.headerLeft{
    width: 12.5%;
}

@media only screen and (max-width: 600px) {
    .headerLeft{
        width: 50px;
    }
}*/

.ant-select-dropdown, .ant-picker-dropdown{
    z-index: 1350 !important;
}


.div-calendar .fc-time-grid .fc-slats tr td:last-child {
    position: relative;
    cursor: pointer;
}

.div-calendar .fc-time-grid .fc-slats tr td:last-child:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: dashed 1px #eaeaea;
    height: 1px;
}

.div-calendar .fc-widget-content {
    /*overflow-y: auto;*/
    height: 100%;
}


#calendarRoot .fc-time-grid-container .fc-slats tr:hover {
    background: #fcf8e3;
}

table.fc-agenda-slots td.fc-widget-content {
    border-right: 1px #DDD solid;
}

table.fc-agenda-slots td.fc-widget-content:hover {
    background-color: #F1F1F1;
}

.clearfix {
    /*overflow: auto;*/
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

#meeting .fc-unthemed td {
    border-color: #fff;
    z-index: 2;
    opacity: 1;
}

#time .DateRangePicker {
    z-index: 10;
}

#time .DateRangePickerInput__withBorder {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#time .DateInput_input__small {
    font-size: 1rem;
    color: #000;
}

#time .DateInput__small {
    width: auto !important;
}

#time .DateInput_input__focused {
    outline: none;
    color: #000;
}

.editVote .daterangepicker {
    position: static;
}

.editVote input {
    width: 100%;
    padding: 5px;
    outline: none;
    text-align: center;
}

.meetingSuccses {
    animation: succes 1s infinite;
    font-size: 13px;
    display: inline-block;


}

@keyframes succes {
    from {
        color: #000;
    }
    to {
        color: #ff0000;
    }

}

.timeDrag {
    display: none;
}

.fc-mirror .timeDrag {
    display: block;
    font-size: 7px;
}

.colorWhite {
    color: #fff;
}

.colorWhite svg {
    max-width: 15px;
    max-height: 15px;
}

.colorBlack {
    color: #000
}

.colorBlack svg {
    max-width: 15px;
    max-height: 15px;
}

/*svg {*/
/*    max-width: 15px;*/
/*}*/

.div-calendar .fc-body > tr {
    overflow-y: hidden;
}

.div-calendar .fc-body > tr > td {
    border: none;
}

.timeCalendar {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
}

.timeCalendar .about {
    margin: 0 10px;
}

.timeCalendar .bp3-input-group {
    width: 205px;
}

.scrollLeftEvent {
    overflow: hidden;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 84px;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
    padding: 0px 10px 10px 0;
    /*background: #f7f7f7;*/
    box-sizing: border-box;
}

.leftEventMobile {
    position: static;
    min-width: auto;
}

.scrollLeftEvent:hover {
    overflow: auto;

}

.scrollLeftEvent::-webkit-scrollbar {
    display: none
}

.contentEvent {
    overflow-y: hidden;
    margin-left: 16.666667%;
    overflow-x: auto;
}

.contentEventMobile {
    margin-left: 0px;
    overflow: auto;
}

.hiddenDisplay {
    display: none;
}

.titleChat {
    line-height: 25px;
    background: #1a73e8;
    color: #fff;
    width: 350px;
    text-align: left;
    padding: 10px;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
    position: relative;
    display: flex;
}

.titleChatFocus {
    line-height: 25px;
    background: #1a73e8;
    color: #fff;
    width: 350px;
    text-align: left;
    padding: 10px;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
    position: relative;
    display: flex;
    animation: colorBackground 1.5s ease-out infinite;
    -moz-animation: colorBackground 1.5s ease-out infinite;
    -webkit-animation: colorBackground 1.5s ease-out infinite;
    -o-animation: colorBackground 1.5s ease-out infinite;

}

.cursorPointer {
    cursor: pointer;
}

@keyframes colorBackground {
    0% {
        background-color: #fff;
        color: #000;
    }
    50% {
        background-color: #1a73e8;;
        color: #fff;
    }
}

@media screen and (max-width: 1400px) {
    .scrollLeftEvent {
        min-width: 260px;
    }

    .contentEvent {
        margin-left: 260px;
    }
}

@media screen and (max-width: 1200px) {


    .timeCalendar .bp3-input-group {
        width: auto;
    }
}

@media screen and (max-width: 960px) {
    .DateRangePicker_picker__directionLeft {
        left: 0 !important;
    }

    .titleChat {
        width: 250px;
    }

    .titleChatFocus {
        width: 250px;
    }

    .timeCalendar {
        /*flex-direction: column;*/
    }

    .timeCalendar .about {
        margin: 0 3px;
    }
}

.test {
    margin: 10px 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    max-height: 495px;
}

@media screen and (max-height: 760px) {
    .test {
        max-height: 270px;
    }
}

/*"&::-webkit-scrollbar": {*/
/*                          width: 8,*/
/*                      },*/
/*"&::-webkit-scrollbar-thumb": {*/
/*                                borderRadius: 10,*/
/*                                backgroundColor: '#ccc',*/
/*                            },*/
/*"@media (min-height: 760px)": {*/
/*                                height: '395px',*/
/*                            }*/
/*}*/
@media screen and (max-width: 600px) {
    .leftEventMobile, .scrollLeftEvent {
        position: static;
        min-width: auto;
    }

    .contentEvent {
        margin-left: 0px;
    }

    .timeCalendar {
        flex-direction: column;
    }

}

#calendarRoot .fc-content-skeleton {
    padding-bottom: 0;
}

.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
    height: auto !important;
}

.smooth-dnd-container.horizontal {
    display: flex !important;
    flex-wrap: wrap;
}

body {
    font-family: source-han-sans-japanese, sans-serif !important;
    color: #000000;
    line-height: 1.3;
}

.fontSourceHanSans {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.MuiTypography-root, .MuiTab-wrapper {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.tracks div {
    font-family: source-han-sans-japanese, sans-serif !important;
}

#tutorial-settings .MuiInputBase-root, #tutorial-settings .MuiInputBase-input {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.ItemCheckedCustom h1 {
    font-family: source-han-sans-japanese, sans-serif !important;
}

/*.fontBebasNeue {*/
/*    font-family: source-han-sans-japanese, sans-serif !important;*/
/*}*/

.isSun {
    color: #ff867f;
}

.isSat {
    color: #1B97F0;
}

.headerCalendar {
    font-weight: bold;
    position: relative;
    border: solid 1px transparent;
    font-size: 11px;
}

.headerCalendar .headerHtml {
    height: 25px;
    line-height: 25px;
    margin: 2px auto;
    text-align: center;
}


.isSelection {
    border: solid 1px red;
}

.material-icons-fix {
    vertical-align: middle;
}

.div-calendar .fc-time-grid .fc-slats tr td:last-child:hover {
    background: #fcf8e3;
}

.div-calendar .fc-time-grid > .fc-bg {
    z-index: 2 !important;
}

.div-calendar .fc-time-grid > .fc-bg .fc-day {
    cursor: pointer;
}

.headerCalendar {
    cursor: pointer;
}

.calendarDivCol3 .headerCalendar {
    font-size: 6px;
}

.calendarDivCol3 .headerCalendar .headerDay, .calendarDivCol2 .headerCalendar .headerDay {
    padding: 4px 0;
}

.calendarDivCol3 .headerCalendar .isToday {
    width: 10px;
    height: 10px;
    line-height: 11px;
    margin: 9px auto;
}

.calendarDivCol2 .headerCalendar {
    font-size: 9px;
}

.calendarDivCol2 .headerCalendar .isToday {
    width: 15px;
    height: 15px;
    line-height: 15px;
    margin: 7px auto;
}

.popNotification {
    position: absolute;
    top: 10px;
    right: 50px
}

.popNotification button:hover {
    background-color: unset;
}

.ant-btn-clicked:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}

[ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}

.fc-event {
    border-width: 3px !important;
}

.pastEvent {
    opacity: 1;
}

/*Custom Calendar*/
.headerCalendar {
    color: #000;
}

.fc-theme-standard td, .fc-theme-standard th {
    /*background: #fff;*/
    border: solid 1px #e7e5e5 !important;
}

.fc-theme-standard .fc-scrollgrid {
    border: none !important;
}

.fc .fc-scroller-harness {
    background: #fff;
}

.fc-col-header-cell-cushion {
    display: block !important;
    padding: 0 !important;
}

.headerDay {
    border-bottom: solid 1px #fff;
    padding: 4px 0;
    text-overflow: clip;
    overflow: hidden;
}

.fc .fc-timegrid-slot-label {
    border: none !important;
    font-weight: bold;
    font-size: 11px;
}

.fc .fc-timegrid-axis-frame {
    font-size: 11px;
    font-weight: bold;
}

.fc-direction-ltr .fc-timegrid-col-events {
    margin: 0 1px !important;
}

.fc-day-sat .headerCalendar {
    color: #1790FF;
}

.fc-day-sun .headerCalendar {
    color: #ff0000;
}

.headerCalendar .isToday {
    width: 21px;
    height: 21px;
    line-height: 21px;
    margin: 4px auto;
    text-align: center;
    background-color: #1790FF;
    color: #fff;
    border-radius: 50%;
}

.fc-timegrid-slots table tbody tr {
    font-size: 8px;
}

.fc .fc-timegrid-slot-label {
    font-size: 8px;
}

.fc .fc-timegrid-slot {
    height: unset !important;
}

.fc-timegrid-slot-lane:hover {
    background: #f3eed3;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transform: translateY(-50%);
    padding-top: 5px;
    /*background-color: #f1f1f1;*/
}

.fc .fc-timegrid-slot-label-cushion {
    background-color: #fff;
    padding: 0 2px 0 8px !important;
}

/*.fc .fc-daygrid-event-harness {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*}*/

.fc .fc-timegrid-slots > table > tbody > tr:nth-child(1) > td:nth-child(1) {
    /*display: none;*/
}

.fc-timegrid-slots table tbody tr {
    border-bottom: solid 1px #434343;
}

.buttonIcon2 {
    margin-left: 5px;
    margin-bottom: 5px;
    border: none !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: #fff !important;
    box-shadow: none;

}

.buttonIcon2 img {
    width: 100%;
    height: 100%;
    max-width: 30px;
}

.eventRenderCustom1 {
    position: relative;
}

.eventRenderCustom1 .checkBoxRenderEvent {
    position: absolute;
    top: 0;
    right: 0;
}

.eventRenderCustom2 {
    position: relative;
}

.eventRenderCustom2Height {
    min-height: 10px;
    position: relative;
}

.eventRenderCustom2 .checkBoxRenderEvent {
    position: absolute;
    bottom: 1px;
    /*left: 50%;*/
    /*margin-left: -7px;*/
}

.eventRenderCheckBox {
    /*position: absolute;*/
    bottom: 0px;
    left: 50%;
    width: 16px;
    height: 16px;
    object-fit: contain;
    /*margin-left: -8px;*/
}

.eventRenderMile {
    position: absolute;
    width: 16px;
    height: 16px;
    object-fit: contain;
    top: 0px;
    right: 0px;
}

.eventRenderMile span {
    font-size: 15px;
}

.eventRenderCheckBox span {
    font-size: 15px;
}

.ck-rounded-corners .ck.ck-balloon-panel, .ck.ck-balloon-panel.ck-rounded-corners {
    z-index: 9999 !important;
}

.hiddenArrowInput input::-webkit-outer-spin-button,
.hiddenArrowInput input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.hiddenArrowInput input[type=number] {
    -moz-appearance: textfield !important;
}

/*input[type=number]::-webkit-inner-spin-button,*/
/*input[type=number]::-webkit-outer-spin-button {*/
/*    -webkit-appearance: none;*/
/*    margin: 0;*/
/*}*/

@keyframes: global(Toastify__slideInUp) {
    from {
        transform: translate3d(0, 110%, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.Toastify__toast-container {
    z-index: 99999 !important;
}

/*.MuiPopover-root {*/
/*    z-index: 999 !important;*/
/*}*/

.root {
    margin-left: 20px;
    background-color: #434343;
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 20px 10px 20px;
}

.root:before {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: #434343;
    transform-origin: top right;
    transform: skew(-23.4deg, 0deg);
}


.iconEvent {
    font-size: 18px !important;
}


/* z-index */
/*.ant-message{*/
/*    z-index: 2000 !important;*/
/*  }*/

.ant-popconfirm{
      z-index: 1300 !important;
}
